@use '../config/' as *;
.waf-broadcast {
    --video-list-space: 18rem;
    margin-bottom: 0;
    @include tabs();
    @extend %mx-2-neg;
    @extend %bg-neutral-100;
    &.waf-component {
        padding-block: var(--space-20) 9.6rem;
    }
    .head {
        &-wrap {
            @extend %neutral-0;
            .title {
                @include title-with-icon(shape, chart-bar-square, 18, neutral-0);
                @extend %title-40;
                @extend %mb-8;
            }
        }
    }
    .status {
        order: -1;
        border-radius: 10rem;
        width: max-content;
        @extend %neutral-0;
        @extend %p-2-3;
        @extend %flex-n-c;
        @extend %gap-1;
        @extend %mb-6;
        @include bg(neutral-0, 2);
        &::after {
            @include icon(live, 16);
        }
        .text {
            font-weight: 700;
            margin-bottom: 0;
            @extend %neutral-0;
        }
    }
    .counter {
        @extend %flex;
        &-item {
            &.day {
                border-right: .3rem solid var(--secondary-50);
                @extend %pr-2;
                @extend %mr-2;
                @extend %relative;
            }
            &:not(.day, .sec) {
                @extend %mr-4;
                .number {
                    @extend %relative;
                    &::after {
                        content: ":";
                        @include position(0, var(--space-3-neg));
                        @extend %h-100;
                        @extend %flex-n-c;
                        @extend %title-50;
                    }
                }
            }
        }
        .number {
            width: max-content;
            display: inline-block;
            @extend %mb-1;
            @extend %title-50;
        }
        .text {
            line-height: 1;
            margin-bottom: 0;
            @extend %font-12;
            @extend %text-center;
        }
    }
    .tab {
        &s {
            gap: 0;
            margin-inline: 0;
            @extend %flex-n-c;
            .tab-text {
                color: var(--neutral-20);
                @extend %capitalize;
                @extend %font-16-pr;
            }
        }
        &-name {
            height: 6.4rem;
            width: max-content;
            cursor: pointer;
            @extend %flex-n-c;
            @extend %px-6;
            @include border(3, neutral-100, 10, bottom, solid);
            &.active {
                @include border(3, neutral-5, 10, bottom, solid);
                .tab-text {
                    @extend %neutral-5;
                }
            }
            &:hover {
                .tab-text {
                    @extend %neutral-5;
                }
            }
        }
        &-anchor {
            @extend %h-100;
        }
        &-section {
            @extend %mb-20;
        }
        &-container-item {
            @extend %relative;
            @extend %px-4;
            @extend %pt-10;
            &::before {
                content: "";
                background: linear-gradient(269deg, var(--primary-90) -25.19%, var(--primary-50) 99.83%);
                @extend %w-100;
                @extend %h-100;
                @extend %position-t-l;
                @extend %border-radius-m;
            }
        }
        &-container-wrapper {
            @extend %relative;
        }
    }
    .card {
        &-list {
            gap: var(--space-4);
            margin-top: calc(var(--video-list-space) * -1);
            bottom: calc(var(--video-list-space) * -1);
            @extend %relative;
            @extend %px-8;
            @extend %mx-8-neg;
            @extend %x-auto;
            @extend %flex;
        }
        &-item {
            width: calc(95% - var(--space-4));
            flex-shrink: 0;
            @extend %relative;
            &:hover {
                .card-thumbnail {
                    img {
                        transition: 500ms ease-in-out;
                        transform: scale(1.03);
                    }
                }
            }
        }
        &-time {
            height: 3.2rem;
            @include position(var(--space-4), null, null, var(--space-4));
            @extend %font-14-pb;
            @extend %px-3;
            @extend %flex-c-c;
            @extend %border-radius-l;
            @extend %neutral-0;
            @include bg(neutral-0, 3);
        }
        &-content {
            @extend %mt-6;
        }
        &-title {
            @include truncate(2, 24);
            line-height: 3rem;
            height: auto;
            @extend %neutral-0;
        }
        &-footer {
            @extend %w-100;
            @extend %h-100;
            @extend %position-t-l;
            .text {
                @extend %w-100;
                @extend %h-100;
                @extend %flex;
                @extend %font-0;
            }
        }
        &-status {
            @extend %neutral-0;
            @extend %mb-8;
        }
        &-label {
            @extend %text-left;
            @extend %mb-2;
        }
        &-thumbnail {
            img {
                transition: 500ms ease-in-out;
            }
        }
    }
    .head {
        .title {
            @extend %neutral-0;
            @extend %font-20-pb;
            @extend %mb-6;
        }
        .text {
            @extend %mb-10;
            @extend %neutral-20;
            @extend %font-14-pr;
        }
    }
    .body {
        @extend %border-radius-m;
        @extend %px-4;
        @extend %py-6;
        @extend %mx-4-neg;
        @extend %bg-neutral-0;
    }
    .broadcast-list {
        .desc {
            @extend %mt-6;
            @extend %neutral-70;
            @extend %font-12-pr;
            @extend %text-center;
        }
        .title {
            @extend %neutral-100;
            @extend %font-14-pb;
        }
        .sub-title {
            @extend %flex-sb-c;
            @extend %py-4;
            @extend %px-2;
            @include border(1, neutral-100, 10, bottom, solid);
            .text {
                @extend %capitalize;
                @extend %neutral-100;
                @extend %font-14-pb;
            }
        }
    }
    .accordion {
        &-item {
            @extend %py-4;
            @extend %px-2;
            @include border(1, neutral-20, 10, bottom, solid);
        }
        &-header {
            @extend %flex-sb-c;
            @extend %w-100;
            @extend %relative;
            .btn-text {
                width: 85%;
                @extend %text-left;
                @extend %capitalize;
                @extend %neutral-100;
            }
        }
        &-content {
            .text {
                @extend %neutral-100;
                @extend %font-12-pr;
            }
        }
    }
    .streaming {
        margin-top: 12rem;
        margin-bottom: var(--video-list-space);
        .title {
            @extend %text-left;
            @extend %capitalize;
            @extend %neutral-0;
            @extend %font-20-pb;
            @extend %mb-6;
            a {
                color: inherit;
                text-decoration: underline;
            }
        }
        .logo {
            width: 15rem;
            height: 3rem;
            @extend %mb-10;
            @extend %mx-auto;
        }
        .text {
            @extend %text-left;
            @extend %mb-10;
            @extend %neutral-20;
            @extend %font-14-pr;
        }
        .btn-site {
            width: fit-content;
            @extend %px-5;
            @extend %py-4;
            @extend %border-radius-l;
            @extend %bg-neutral-0;
            @extend %primary-50;
            &:hover {
                @extend %bg-neutral-5;
                @extend %primary-55;
            }
            &:focus {
                @extend %bg-neutral-0;
                @extend %primary-85;
            }
        }
        .btn-text {
            @extend %capitalize;
            @extend %font-14-pb;
        }
        &-info {
            @include flex-config(flex, column-reverse);
        }
        &-content {
            @extend %text-center;
        }
        &-thumbnail {
            margin-top: calc(var(--space-20-neg) + var(--space-10-neg));
            margin-bottom: var(--space-8);
            @extend %flex-c-n;
            img {
                width: 30rem;
                height: 21rem;
            }
        }
        &-list {
            flex-wrap: wrap;
            gap: var(--space-6);
            @extend %my-18;
            @extend %flex-c-c;
        }
    }
    .content-app {
        margin-top: calc(var(--space-24-neg) + var(--space-24-neg));
        @extend %mb-4;
        .title {
            @extend %neutral-100;
        }
        .desc {
            @extend %neutral-70;
        }
        .btn-site {
            @extend %p-0;
            @extend %bg-transparent;
        }
    }
    &.waf-multimedia-broadcast {
        @extend %bg-transparent;
        .card-list {
            margin-top: -8.4rem;
        }
        .card-title {
            @extend %neutral-100;
        }
        .streaming {
            .logo {
                height: 3rem;
                margin-inline: unset;
                @extend %mb-8;
            }
            &-thumbnail {
                margin-top: calc(var(--space-24-neg) + var(--space-24-neg));
                @extend %mb-0;
                img {
                    width: calc(100% + var(--space-8));
                    aspect-ratio: 11/10;
                    height: auto;
                }
            }
        }
    }
    &.waf-upcoming-livestream {
        @extend %bg-transparent;
        &.waf-component {
            @extend %pt-0;
            @extend %pb-20;
        }
        .streaming {
            @extend %mt-0;
            @extend %mb-0;
            @extend %pb-10;
            .logo {
                margin-inline: unset;
                @extend %mb-6;
            }
            &-info {
                flex-direction: column;
            }
            &-thumbnail {
                @extend %mt-12;
                @extend %mb-0;
                img {
                    width: auto;
                    height: 17.5rem;
                    @extend %border-radius-r;
                }
            }
            &-content {
                @extend %text-left;
                &-info {
                    @extend %flex-column;
                }
            }
        }
    }
}
@include mq(col-tablet) {
    .waf-broadcast {
        --video-list-space: 14rem;
        &.waf-component {
            padding-bottom: var(--space-20);
        }
        .head-wrap {
            .title {
                margin-bottom: var(--space-13);
            }
        }
        .tab {
            &s {
                .tab-text {
                    font-weight: 700;
                }
            }
            &-section {
                margin-bottom: 8.5rem;
            }
        }
        .card {
            &-list {
                padding-inline: var(--space-0);
                margin-right: var(--space-0);
                margin-left: var(--space-9-neg);
            }
            &-item {
                width: calc(25% - var(--space-4));
            }
        }
        .broadcast-list {
            .title {
                font-size: 2rem;
                margin-bottom: var(--space-4);
            }
        }
        .accordion {
            &-header {
                width: calc(100% - 20%);
                cursor: auto;
                &::after {
                    content: unset;
                }
                .btn-text {
                    text-align: left;
                    width: 100%;
                    @include color(neutral-100);
                }
                &[aria-expanded="true"] {
                    ~ .accordion-body {
                        padding-top: 0;
                    }
                }
            }
            &-item {
                padding-block: var(--space-2);
                @include flex-config(flex, space-between, center);
            }
            &-body {
                width: 20%;
                padding-left: var(--space-4);
                @include flex-config(flex, null, flex-end);
            }
            &-content {
                text-align: right;
                .text {
                    @include color(neutral-70);
                }
            }
        }
        .streaming {
            margin-top: var(--space-10);
            padding-left: var(--space-14);
            .title {
                font-size: 4rem;
                line-height: 4.8rem;
            }
            .logo {
                margin-inline: inherit;
                margin-bottom: var(--space-6);
            }
            .text {
                font-size: 1.6rem;
            }
            &-list {
                gap: var(--space-18);
            }
            &-info {
                flex-direction: row;
            }
            &-content {
                width: 50%;
                text-align: left;
            }
            &-thumbnail {
                width: 50%;
                margin-bottom: var(--space-0);
                margin-top: calc(var(--space-22-neg) + var(--space-10-neg));
                img {
                    width: 100%;
                    height: auto;
                    object-fit: contain;
                }
            }
            .card-footer {
                .text {
                    font-size: 0;
                }
            }
        }
        .content-app {
            margin-top: 0;
            margin-bottom: 0;
            .title {
                font-size: 2.4rem;
                line-height: 3.36rem;
                margin-bottom: var(--space-2);
            }
            .desc {
                font-size: 1.6rem;
                margin-bottom: var(--space-4);
            }
        }
        &.waf-multimedia-broadcast {
            .tab-container-item::before {
                width: var(--window-inner-width);
                left: calc(-1 * ((var(--window-inner-width) - var(--container-max-width)) / 2));
            }
            .card-list {
                margin-inline: 0;
            }
            .streaming {
                margin-top: 0;
                padding-inline: var(--space-14);
                &-thumbnail {
                    margin-top: calc(var(--space-23-neg));
                }
                &-info {
                    align-items: center;
                    justify-content: space-between;
                }
                &-thumbnail {
                    justify-content: flex-start;
                }
                &-content {
                    flex-shrink: 0;
                }
            }
        }
        &.waf-upcoming-livestream {
            .streaming {
                padding: var(--space-18);
                .title {
                    font-size: 3.2rem;
                    line-height: 4.1rem;
                }
                .logo {
                    width: 22rem;
                }
                &-info {
                    flex-direction: row;
                    align-items: center;
                    gap: 3.7%;
                }
                &-content {
                    width: 46%;
                }
                &-thumbnail {
                    width: auto;
                    margin-top: 0;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }
}
@include mq(col-lg) {
    .waf-broadcast {
        .head {
            width: calc(37% - var(--space-8));
            padding-right: var(--space-10);
            .title {
                font-size: 4rem;
                line-height: 4.6rem;
            }
            .text {
                font-size: 1.6rem;
            }
        }
        .body {
            width: 63%;
            max-height: 119rem;
            margin-inline: var(--space-0);
            padding: var(--space-10);
            overflow-y: auto;
            margin-top: var(--space-22-neg);
            @include custom-scroll;
        }
        .tab {
            &s {
                .tab-text {
                    font-size: 2rem;
                }
            }
        }
        .television {
            padding-left: var(--space-14);
            margin-bottom: 2.5rem;
            &::before {
                max-height: 67rem;
            }
            .tab-container-wrapper {
                display: flex;
            }
        }
    }
}