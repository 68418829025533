@forward './main.scss';
@forward './swiper/swiper-bundle';
@forward './showcase/showcase';
@forward './module/banner';
@forward './standings/standings';
@forward './module/event-banner';
@forward './listing/listing-common';
@forward './events/the-races';
@forward './module/triathlon-live';
@use './config/' as *;
.waf-multimedia-listing {
  .preview-swiper {
    margin-inline: var(--space-4-neg);
  }
  .article-list {
    padding-inline: var(--space-4);
    box-sizing: border-box;
    .article-item {
      width: 80%;
    }
  }
}
.load-bg-pattern {
  .waf-discover-banner {
    .layout-wrapper {
      background: url(/static-assets/images/cssimages/pattern/discover-more-bg-m.png?v=#{$image-version}) left top / cover no-repeat;
    }
  }
  .waf-ranking {
    @include bgImg("svg/noise-gradient.svg", center, cover);
  }
}
.waf-showcase {
  .showcase-wrapper {
    margin-top: var(--secondary-header-height);
  }
}
.waf-broadcast {
  &.no-card-list {
    .streaming {
      margin-bottom: 0;
      padding-bottom: var(--space-4);
    }
  }
}
@media (min-width:768px) {
  .waf-multimedia-listing {
    .preview-swiper {
      margin-inline: auto;
    }
    .article-list {
      padding-inline: unset;
      box-sizing: content-box;
      .article-item {
        width: 100%;
      }
    }
  }
  .load-bg-pattern {
    .waf-discover-banner {
      .layout-wrapper {
        @include bgImg("cssimages/pattern/discover-more-bg-d.png");
      }
    }
  }
  .waf-showcase {
    .showcase-wrapper {
      margin-top: 0;
    }
  }
}