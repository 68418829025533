@use '../config/' as *;
.waf-banner {
	&.app-banner {
		img {
			border-radius: 0;
			height: 100%;
		}
	}
}
.app-banner {
	@extend %mx-2-neg;
	@extend %bg-neutral-100;
	&.waf-component {
		@extend %mb-0;
	}
	.banner {
		&-wrapper {
			@extend %bg-neutral-0;
			@extend %relative;
			@extend %pt-20;
			@extend %px-6;
			@extend %mb-8-neg;
			@extend %full-radius;
			@extend %zindex-banner-position;
		}
		&-logo {
			max-width: 24rem;
			height: 7.5rem;
			margin: 0 auto;
			@include position(-4rem, 0, null, 0);
			@extend %p-6;
			@extend %full-radius;
			@extend %bg-neutral-0;
		}
		&-content {
			.title {
				@extend %title-30;
				@extend %mb-6;
			}
			.desc {
				@extend %font-16;
			}
		}
		&-thumb {
			@extend %mb-4-neg;
			@extend %ml-8-neg;
			img {
				object-fit: contain;
				object-position: bottom;
			}
		}
		&-cta {
			position: absolute;
			bottom: 0;
			left: 0;
			@extend %px-6;
			@extend %mb-8;
			@extend %w-100;
			.btn-fill {
				@extend %btn-fill;
				@extend %w-100;
			}
		}
	}
	.content-app {
		@extend %mt-6;
		@extend %mb-8;
	}
	.ios-store,
	.play-store {
		height: 4rem;
	}
}
.waf-row-banner {
	@extend %bg-neutral-100;
}
.banner-group {
	@extend %flex-column;
	@extend %gap-4;
}
.waf-component {
	&.waf-support-banner {
		@extend %px-2;
		@extend %pt-20;
	}
	&.waf-store-banner {
		@extend %mb-0;
	}
}
.waf-support-banner {
	margin-inline: var(--space-2-neg);
	margin-bottom: 0;
	@extend %relative;
	@extend %zindex-filter-dropdown;
	.banner-item {
		min-height: 15rem;
		// background: linear-gradient(157deg, var(--primary-50) 0%, var(--primary-80) 100%);
		@extend %border-radius-m;
		@extend %w-100;
		@extend %flex-n-c;
		&.spotify {
			// background: hsl(var(--hsl-spotify-0));
			@extend %zindex-banner-position;
			iframe {
				height: 15.2rem;
				// min-height: 23.25rem;
				// body {
				// 	@extend %flex-c-c;
				// }
			}
		}
		a {
			@extend %w-100;
			@extend %h-100;
		}
	}
	.banner-group {
		@extend %mb-13-neg;
	}
}
.waf-antidoping-banner {
	padding-block: 4.6rem var(--space-20);
	margin-bottom: 0;
	.layout-wrapper {
		@extend %flex-column;
		@extend %gap-4;
	}
	img {
		min-height: 14.6rem;
		@extend %border-radius-m;
	}
}
.waf-row:not(.waf-row-banner) {
	.waf-support-banner {
		@extend %pt-12;
		@extend %pb-20;
		.banner-group {
			@extend %mb-0;
		}
	}
}
.waf-store-banner {
	border-radius: var(--border-radius-l) var(--border-radius-l) 0 0;
	background: linear-gradient(180deg, hsl(var(--hsl-neutral-100)/0) 0%, hsl(var(--hsl-neutral-100)/0) 100%),
	linear-gradient(0deg, hsl(var(--hsl-pure-black-0)/.3) 0%, hsl(var(--hsl-pure-black-0)/.3) 100%),
	url('/static-assets/images/cssimages/store-web.webp?v=#{$image-version}');
	background-size: cover;
	background-position: center;
	@extend %mx-2-neg;
	@extend %relative;
	&::after {
		content: '';
		background-image: linear-gradient(0deg, transparent, var(--neutral-100), transparent);
		pointer-events: none;
		@include position(null, null, -20%, 0);
		@extend %w-100;
		@extend %h-40;
	}
	.banner {
		&-logo {
			width: 25rem;
			height: 11rem;
			@extend %mb-8;
		}
		&-image {
			border-radius: 0;
		}
		&-item {
			height: auto;
			padding: 7rem 3rem;
			@extend %bg-transparent;
		}
		&-title {
			@extend %title-20;
			@extend %neutral-0;
			@extend %mb-9;
		}
		&-btn {
			@extend %border-radius-xl;
			@extend %bg-secondary-50;
			@extend %neutral-100;
			@extend %p-3-5;
			@extend %text-mb;
			@extend %capitalize;
		}
	}
}
//for eventlisting page
.sticky-container-banner {
	&::after {
		content: '';
		position: absolute;
		@extend %bg-neutral-100;
		@extend %w-100;
		@extend %h-100;
		@extend %zindex-banner-event-overlay;
	}
}
.waf-banner {
	img {
		border-radius: 1.6rem;
		// object-fit: cover;
		@extend %h-100;
	}
}
.waf-discover-banner {
	padding: 9.6rem 0 var(--space-10);
	margin: 0 var(--space-2-neg);
	@extend %bg-neutral-100;
	.layout-wrapper {
		padding: var(--space-10) var(--space-2) 0;
		background: linear-gradient(45deg, var(--secondary-30), var(--primary-30));
		@extend %border-radius-m;
	}
	.banner {
		&-content {
			@extend %flex-column-n-c;
		}
		&-logo {
			@extend %mb-8;
			.image {
				width: 12rem;
				aspect-ratio: 3;
				border-radius: unset;
				filter: invert(1);
				object-fit: contain;
			}
		}
		&-title {
			@extend %mb-6;
			@extend %neutral-5;
			@extend %title-40;
			@extend %text-center;
		}
		&-link {
			gap: var(--space-2);
			@extend %mb-14;
			@extend %flex-n-c;
			@extend %neutral-5;
			@extend %text-mb;
			&::after {
				font-weight: 400;
				rotate: 45deg;
				@include icon(arrow-up, 24);
			}
		}
	}
}
.waf-elite-banner {
	padding-block: var(--space-20);
	margin-bottom: var(--space-0);
	@extend %px-2;
	.banner-wrapper {
		@extend %p-10-4;
		@extend %border-radius-m;
		@include bgImg("cssimages/pattern/banner-gradient-mob.png", center, cover);
	}
	.title {
		@extend %neutral-5;
		@extend %title-40;
	}
	.sub-title {
		@extend %neutral-5;
		@extend %body-mb;
	}
	.banner-cta {
		@extend %pt-8;
		@extend %flex-c-n;
		.btn-fill {
			@extend %bg-neutral-0;
			@extend %primary-50;
			@extend %btn-fill;
			&:hover {
				@extend %bg-neutral-5;
			}
		}
	}
}
.waf-arena-banner {
	padding: 0;
	margin-inline: var(--space-2-neg);
	margin-bottom: 0;
	z-index: map-get($map: $zindex, $key: arena-banner);
	@extend %relative;
	.layout-wrapper {
		@include background(null, 'cssimages/pattern/blue-banner-gradient-mobile.png', center / cover no-repeat);
		@extend %p-8-4;
		@extend %border-radius-m;
	}
	.banner {
		&-title {
			@extend %mt-0;
			@extend %neutral-0;
			@extend %title-40;
		}
		&-text {
			@extend %mb-6;
			@extend %neutral-5;
		}
		&-link {
			width: fit-content;
			background-color: var(--neutral-0);
			color: var(--primary-50);
			@extend %mb-16;
			@extend %btn-fill;
			&:hover {
				color: var(--neutral-0);
			}
			.text {
				font-weight: 700;
			}
		}
		&-right {
			width: 22rem;
		}
	}
}
@include mq(col-tablet) {
	.app-banner {
		.banner {
			&-wrapper {
				padding-bottom: 7.8rem;
			}
			&-thumb {
				display: none;
			}
		}
		.content-app {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}
	.waf-support-banner {
		.banner-item {
			flex-basis: 60%;
			// height: 22rem;
			&.spotify {
				flex-basis: 40%;
				iframe {
					// min-height: 22rem;
					height: 23.25rem;
				}
			}
		}
		.banner-group {
			margin-bottom: var(--space-6-neg);
		}
	}
	.banner-group {
		flex-direction: row;
	}
	.waf-store-banner {
		padding: var(--space-22) 0;
		.banner {
			&-item {
				padding-inline: 0;
				gap: var(--space-6);
				@include flex-config(flex, row, space-between, center);
				&.support-item {
					flex: 1;
				}
			}
			&-content {
				max-width: 46rem;
			}
			&-logo {
				width: 32rem;
				height: 13rem;
			}
		}
	}
	.waf-row:not(.waf-row-banner) {
		.waf-support-banner {
			padding-top: var(--space-12);
		}
	}
	.waf-component.waf-banner {
		padding: var(--space-20) 0;
	}
	.waf-elite-banner {
		.banner-wrapper {
			text-align: center;
			padding-block: var(--space-14);
			@include bgImg("cssimages/pattern/banner-gradient-web.png");
		}
		.title {
			font-size: 4rem;
		}
		.sub-title {
			font-size: 2.4rem;
		}
	}
	.waf-arena-banner {
		.layout-wrapper {
			padding: var(--space-14);
			display: grid;
			gap: var(--space-6);
			grid-template-columns: 1fr 22rem;
			@include bgImg("cssimages/pattern/blue-banner-gradient-desktop.png");
		}
		.banner {
			&-title {
				font-size: 3.2rem;
				line-height: 1.3;
			}
			&-text {
				margin-bottom: var(--space-10);
				font-size: 1.6rem;
			}
			&-link {
				margin-bottom: 0;
			}
			&-right {
				width: unset;
			}
		}
	}
}
@include mq(col-lg) {
	.app-banner {
		margin-inline: var(--space-2-neg);
		padding-top: var(--space-24);
		.banner {
			&-wrapper {
				@include flex-config(flex, row, space-between, center);
				flex-wrap: wrap;
				// display: flex;
				// justify-content: space-between;
				gap: 3.2rem;
				padding-inline: var(--space-13);
				margin-bottom: auto;
				padding-bottom: var(--space-12);
			}
			&-logo {
				margin: unset;
				top: -5rem;
				left: 5rem;
			}
			&-content {
				flex: 0 0 50%;
				width: 50%;
				.title {
					// font-size: 4rem;
					@include truncate(2, 40, 48);
				}
			}
			&-cta {
				position: unset;
				// margin-top: var(--space-8-neg);
				margin-top: 0;
				margin-bottom: 0;
				padding-inline: 0;
				.btn-fill {
					width: max-content;
				}
			}
			&-thumb {
				position: absolute;
				right: 2rem;
				bottom: 0;
				margin-bottom: 0;
				margin-left: 0;
				width: 45%;
				max-width: 40.7rem;
				height: 48.2rem;
				// img {
				// 	height: 100%;
				// }
			}
		}
		.content-app {
			margin-block: 0;
			flex: 0 0 calc(63% - 3rem);
			width: 63%;
			height: 100%;
			align-items: flex-start;
		}
	}
	.waf-antidoping-banner {
		padding-block: var(--space-20) 16rem;
		.layout-wrapper {
			flex-direction: row;
			gap: var(--space-6);
		}
	}
	.waf-discover-banner {
		&.waf-component {
			padding-block: 9.6rem;
		}
		.layout-wrapper {
			height: 31.6rem;
			padding: 0 var(--space-10);
			gap: var(--space-5);
			@include flex-config(flex, row-reverse, space-between);
		}
		.banner {
			&-content {
				width: 54%;
				justify-content: center;
				// padding-block: var(--space-10);
			}
			&-thumbnail .image {
				height: 110%;
				position: relative;
				bottom: 10%;
			}
			&-logo .image {
				width: 14rem;
			}
			&-title {
				font-size: 3rem;
			}
			&-link {
				margin-bottom: 0;
			}
		}
	}
}
@include mq(col-desktop) {
	.app-banner {
		.banner {
			&-wrapper {
				justify-content: unset;
				align-items: unset;
				column-gap: 1rem;
			}
			&-content {
				flex: 0 0 37%;
				width: 37%;
				.title {
					margin-bottom: 0;
				}
				.desc {
					margin-block: var(--space-6);
				}
			}
			&-cta {
				margin-top: var(--space-8-neg);
			}
			&-thumb {
				display: block;
			}
		}
	}
	.waf-store-banner .banner-item {
		padding: 0 10rem;
	}
	.waf-discover-banner {
		.banner {
			&-logo {
				margin-bottom: var(--space-6);
			}
		}
	}
}
@include mq(col-xl) {
	// .waf-support-banner {
	// 	.banner-item {
	// 		height: 26rem;
	// 	}
	// }
	.app-banner {
		.banner {
			&-wrapper {
				gap: 3.2rem;
				column-gap: 3.2rem;
			}
			&-content {
				flex: 0 0 40%;
				width: 40%;
			}
			&-thumb {
				right: 6rem;
			}
		}
	}
	.waf-discover-banner {
		.banner {
			&-logo {
				margin-bottom: var(--space-4);
				.image {
					width: 16.8rem;
				}
			}
			&-title {
				font-size: 4rem;
				line-height: 1.1;
				margin-bottom: var(--space-4);
			}
			&-content {
				width: 60%;
			}
		}
	}
}
@include mq(col-xxl) {
	.waf-support-banner {
		.banner-item {
			&.spotify {
				iframe {
					height: 28.1rem;
				}
			}
		}
	}
}