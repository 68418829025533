@use "../config/" as *;
:root {
  --white-space: max(var(--container-white-space), var(--xl-container-white-space));
}
.blue-gradient{
  .waf-races{
    border-radius: 1.6rem 1.6rem 0 0;
    @include bgImg("cssimages/pattern/blue-gradient-top.png", top 100% left, 100% 130%);
  }
}
.waf-races {
  padding: 0;
  margin: 0 var(--space-2-neg);
  overflow: hidden;
  @extend %common-eventstrip;
  .layout-wrapper {
    max-width: 100%;
    @extend %py-20;
  }
  .waf-head {
    padding-inline: var(--white-space);
    @extend %mb-14;
  }
  .title {
    @extend %title-40;
  }
  .desc {
    @extend %neutral-70;
    @extend %body-l;
  }
  .preview-swiper {
    overflow: visible;
  }
  .card {
    &-list {
      align-items: center;
    }
    &-item {
      width: 30.2rem;
      &.swiper-slide-active{
        box-shadow: 0 .2rem 1.5rem 0 hsl(var(--hsl-pure-black-0) / .15);
      }
    }
    &-head {
      height: 15.5rem;
      border-radius: var(--border-radius-m) var(--border-radius-m) 0 0;
      background: linear-gradient(269deg, var(--primary-90) -0.19%, var(--primary-50) 99.83%);
      @extend %relative;
    }
    &-body {
      height: 10.2rem;
      border-radius: var(--border-radius-m) var(--border-radius-m) 0 0;
      padding-bottom: 0;
    }
    &-thumbnail {
      transform: translate(-50%, -50%);
      @include position(46%, null, null, 50%);
      .img-box {
        background: transparent;
      }
    }
    &-image {
      width: auto;
      height: 4.8rem;
      position: unset;
      filter: brightness(0) invert(1);
      @extend %mx-auto;
    }
    &-label {
      margin-top: 0;
    }
    &-action{
      padding: var(--space-4) var(--space-6) var(--space-6);
      border-radius: 0 0 var(--border-radius-m) var(--border-radius-m);
    }
    &-btn{
      .text{
        @extend %bg-neutral-0;
        &:hover{
          @extend %bg-neutral-0;
        }
      }
    }
  }
  .swiper-slide-active {
    .card {
      &-head {
        height: 18rem;
      }
      &-body{
        height: 11rem;
      }
      &-action{
        padding: var(--space-6);
      }
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    border-color: var(--primary-50);
    top: unset;
    bottom: calc(100% + var(--space-14));
    transform: unset;
    @extend %d-none;
    &::after {
      color: var(--primary-50);
      font-size: 2.4rem;
    }
    &.swiper-button-disabled {
			border-color: var(--neutral-20);
			&::after{
				color: var(--neutral-40);
			}
		}
  }
  .swiper-button-prev {
    right: calc(var(--white-space) + var(--swiper-button-width) + var(--space-2));
    left: unset;
  }
  .swiper-button-next {
    right: var(--white-space);
  }
}
@include mq(col-tablet) {
  .blue-gradient{
    .waf-races{
      border-radius: 4rem 4rem 0 0;
      background-size: 100% 150%;
    }
  }
  .waf-races {
    .layout-wrapper {
      padding-block: 14.4rem 0;
    }
    .waf-head {
      width: 50%;
      padding-right: 0;
    }
    .title {
      margin-bottom: var(--space-4);
      font-size: 5.6rem;
      line-height: 1.3;
    }
    .desc {
      font-size: 1.6rem;
    }
    .swiper-button-prev,
    .swiper-button-next {
      display: flex;
    }
  }
}