@use "../config" as *;
.waf-event-banner {
    &.waf-overlay-list {
        @extend %p-0;
        .layout-wrapper {
            max-width: 100%;
        }
        .waf {
            &-head {
                @extend %d-none;
            }
            &-body {
                .article {
                    &-list {
                        @extend %d-block;
                    }
                    &-item {
                        border-radius: 0 0 4rem 4rem;
                        @extend %w-100;
                        @extend %relative;
                    }
                    &-thumbnail {
                        & > a {
                            pointer-events: none;
                            @extend %d-block;
                            @extend %common-gradient;
                            &::after{
                                height: 70%;
                                background: linear-gradient(180deg, hsl(var(--hsl-neutral-100)/0) 0%, hsl(var(--hsl-neutral-100)/0.99) 52.25%, hsl(var(--hsl-neutral-100)/1) 100%);
                                @extend %mb-0;
                            }
                        }
                        .item-type-icon {
                            @extend %d-none;
                        }
                        .img-box{
                            padding-bottom: 133.3333333333%;
                        }
                    }
                    &-description {
                        @extend %d-block;
                        @extend %body-mb;
                        @extend %neutral-20;
                    }
                    &-content {
                        bottom: 13.4rem;
                        right: 0;
                        @include default-wrapper();
                        @extend %px-0;
                        @extend %neutral-5;
                        @extend %position-b-l;
                        @extend %gap-2;
                        @extend %zindex-overlay-icon;
                        & > a {
                            pointer-events: none;
                            &:before {
                                content: unset;
                            }
                        }
                    }
                    &-title {
                        height: auto;
                        @extend %d-block;
                        @extend %mb-0;
                    }
                }
            }
        }
        .icon-time-wrapper {
            order: -1;
            @extend %mb-0;
            @extend %d-block;
            .item-type-icon {
                width: 4rem;
                height: 4rem;
                position: unset;
                @include bg(neutral-0, 2);
                @extend %border-radius-s;
                @extend %flex-c-c;
                @extend %neutral-0;
                &:after {
                    @include icon(chart-bar-square, 18);
                    @extend %neutral-5;
                }
            }
        }
        .article-item{
            border: 0;
        }
    }
    &.waf-event-banner {
        @extend %mx-2-neg;
    }
}
@include mq(col-tablet){
    .waf-event-banner{
        &.waf-overlay-list{
            .waf-body{
                .article-thumbnail{
                    .img-box{
                        padding-bottom: 56.25%;
                    }
                }
            }
        }
    }
}
@include mq(col-lg) {
    .waf-event-banner {
        &.waf-overlay-list {
            .waf-body {
                .article {
                    &-content {
                        bottom: 25rem;
                        max-width: var(--container-max-width);
                        width: 100%;
                        margin-inline: auto;
                        padding-inline: 0;
                    }
                    &-title {
                        font-size: 4rem;
                        line-height: 4.8rem;
                        max-width: 77%;
                    }
                    &-description {
                        font-size: 1.6rem;
                    }
                    &-item {
                        &:hover {
                            .article-thumbnail img {
                                transform: unset;
                            }
                        }
                    }
                }
            }
        }
    }
}