@use "../config" as *;
.waf-ranking {
    border-radius: var(--border-radius-m) var(--border-radius-m) 0 0;
    @extend %bg-primary-30;
    @extend %mx-2-neg;
    .head {
        &-wrap {
            @extend %neutral-0;
            .title {
                @extend %title-40;
                @include title-with-icon(shape, chart-bar-square, 18, neutral-0);
            }
        }
    }
    .desc,
    .sub-title {
        @extend %body-mb;
    }
    .head-tab {
        @extend %mt-4;
        a {
            max-width: 22rem;
            @extend %w-100;
            @extend %btn-fill-dark-icon;
            @extend %gap-2;
            &:after {
                @include icon(arrow-right, 19);
            }
        }
    }
    &.waf-component {
        @extend %mb-0;
        @extend %pt-8;
        @extend %pb-13;
    }
    .table {
        &-row {
            &:not(:first-child) {
                position: relative;
                @extend %gap-2;
                .card {
                    &-logo {
                        width: 1.6rem;
                        height: 1.6rem;
                    }
                }
                .time {
                    @extend %pr-2;
                }
            }
        }
        &-title {
            @extend %d-none;
        }
    }
    @extend %standings-first;
    @extend %ranking-table;
}
.waf-row-standings .waf-ranking {
    &.waf-component {
        padding-block: var(--space-20) 0;
    }
    .head-tab {
        @extend %d-none;
    }
    .table-wrapper {
        @extend %pb-0;
    }
}
@include mq(col-tablet) {
    .waf-row-standings {
        margin-top: -18.4rem;
        .waf-ranking {
            &.waf-component {
                padding-top: 26.4rem;
            }
            .table-wrapper {
                padding-bottom: var(--space-20);
            }
        }
    }
    .waf-ranking {
        .table {
            &-data {
                min-height: 5rem;
            }
            &-item {
                width: calc(50% - var(--space-3));
            }
            &-wrapper {
                display: flex;
                gap: var(--space-6);
            }
            &-footer {
                justify-content: flex-start;
            }
            &-row {
                padding-inline: var(--space-6);
            }
        }
        .form-select-box {
            display: none;
        }
    }
}
@include mq(col-lg) {
    .waf-ranking {
        .table {
            &-item {
                width: calc(50% - var(--space-6));
            }
            &-wrapper {
                gap: var(--space-12);
            }
        }
    }
}
@include mq(col-desktop) {
    .waf-ranking {
        border-radius: 4rem 4rem 0 0;
        background-position: bottom;
        &.waf-component {
            padding-block: var(--space-20);
        }
        .layout-wrapper {
            display: flex;
        }
        .waf {
            &-head {
                width: 33rem;
                .title {
                    font-size: 3.5rem;
                }
            }
            &-body {
                width: calc(100% - 33rem);
            }
        }
        .table {
            &-wrapper {
                padding-block: 22rem 10rem;
            }
        }
        .desc,
        .sub-title {
            font-size: 1.6rem;
        }
        .head-tab {
            margin-top: var(--space-14);
        }
    }
    .waf-row-standings {
        .waf-ranking {
            .layout-wrapper {
                display: block;
            }
            .table-wrapper {
                padding-top: 10rem;
            }
            .waf-head {
                width: 50%;
            }
            .waf-body {
                width: calc(100% - 30.2rem);
                margin-left: auto;
            }
        }
    }
}