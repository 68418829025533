@use "../config" as *;
.swiper {
	&-button-next,
	&-button-prev {
		width: var(--swiper-button-width);
		height: var(--swiper-button-height);
		aspect-ratio: 1/1;
		border: .1rem solid var(--neutral-40);
		cursor: pointer;
		@extend %bg-transparent;
		@extend %rounded-radius;
		@extend %flex-c-c;
		&.swiper-button-disabled {
			cursor: not-allowed;
			border-color: var(--neutral-20);
			&::after{
				@extend %neutral-40;
			}
		}
		&::after {
			font: 2.4rem/1 $font-icon;
			@extend %neutral-0;
		}
	}
	&-button-prev {
		&::after {
			@include icon(chevron-left);
		}
	}
	&-button-next {
		&::after {
			@include icon(chevron-right);
		}
	}
	&-pagination-bullet {
		width: 1rem;
		height: .2rem;
		border-radius: var(--half-radius);
		background-color: hsl(var(--hsl-neutral-0)/.7);
		&-active-main {
			width: 2rem;
			height: .4rem;
			background-color: var(--neutral-100);
		}
	}
}