@use "../config" as *;
.waf-showcase {
  @extend %bg-neutral-0;
  @extend %article-tagline;
  &.waf-component {
    margin: 0 var(--space-2-neg);
    @extend %p-0;
  }
  .layout-wrapper {
    max-width: unset;
    margin: 0;
  }
  .showcase-wrapper {
    @extend %relative;
    .btn-site {
      width: fit-content;
      background-color: var(--neutral-0);
      color: var(--primary-50);
      @extend %btn-fill;
      &:hover {
        background-color: var(--neutral-5);
        color: var(--primary-55);
      }
      &:focus {
        background-color: var(--neutral-0);
        color: var(--primary-85);
      }
    }
  }
  .swiper {
    &-button-prev,
    &-button-next {
      @extend %d-none;
    }
  }
  .preview-swiper {
    @extend %h-100;
    @extend %relative;
    .article {
      &-thumbnail {
        height: calc(.85 * max(var(--window-inner-height), 63rem));
        // height: var(--window-inner-height);
        .img-box {
          background-color: transparent;
          border-radius: 0 0 1.6rem 1.6rem;
          @extend %h-100;
          @extend %common-gradient;
        }
        img {
          object-fit: cover;
          object-position: center;
          @extend %w-100;
          @extend %h-100;
          @extend %relative;
        }
      }
      &-content {
        width: var(--container-max-width);
        transform: translateX(-50%);
        @include position(null, null, calc(4.7rem + var(--space-6)), 50%);
        @extend %w-100;
        @extend %zindex-overlay-icon;
      }
      &-title {
        @include truncate-text(3);
        @extend %title-40;
        @extend %mb-2;
        @extend %neutral-5;
      }
      &-description p {
        @include truncate-text(2);
        @extend %neutral-5;
        @extend %body-l;
      }
      &-action {
        width: 45%;
        @extend %mt-4;
      }
    }
    .swiper-pagination {
      width: var(--container-max-width);
      height: fit-content;
      bottom: 4.5rem;
      left: 50%;
      gap: .8rem;
      translate: -50% 0;
      @extend %flex;
      &-bullet {
        width: calc(25% - .6rem);
        height: .2rem;
        flex: 1;
        @extend %relative;
        @extend %bg-neutral-40;
        &-active {
          background-color: var(--neutral-0);
          &::before {
            content: "";
            width: 0;
            animation: carouselDotsAnimation 5000ms linear .3s forwards;
            @include position-combo("tl");
            @extend %h-100;
            @extend %bg-secondary-50;
          }
        }
      }
      .swiper-pagination-bullet {
        @extend %m-0;
      }
    }
  }
  .thumbnail-swiper {
    @extend %d-none;
    .swiper-slide-thumb-active {
      .article-title {
        @extend %neutral-5;
      }
    }
  }
  .article-content {
    @extend %flex-column;
  }
}
@include mq(col-tablet) {
  .livestrip .waf-showcase {
    .showcase-wrapper,
    .waf-showcase.waf-home-showcase .preview-swiper .article-thumbnail {
      height: 80rem;
    }
  }
  .waf-showcase.waf-home-showcase {
    .preview-swiper {
      .article-thumbnail {
        height: 80rem;
      }
    }
  }
  .waf-showcase {
    .preview-swiper {
      .article {
        &-thumbnail {
          height: 80rem;
          .img-box {
            border-radius: 0 0 3.2rem 3.2rem;
          }
          img {
            object-position: top center;
          }
        }
        &-content {
          bottom: 32%;
          > a {
            display: block;
          }
        }
        &-title {
          font-size: 3.2rem;
          line-height: 1.3;
        }
      }
      .swiper-pagination {
        top: calc(83% - 1rem);
        bottom: unset;
        gap: 2.4rem;
        &-bullet {
          width: calc(25% - 1.8rem);
          height: .4rem;
          flex: unset;
        }
      }
    }
    .thumbnail-swiper {
      width: var(--container-max-width);
      display: block;
      translate: -50% 0;
      @include position(83%, null, null, 50%);
      .article {
        &-title {
          margin-bottom: 0;
          color: var(--neutral-40);
          font: 400 1.6rem/1.5 $font-primary;
          @include truncate-text(3);
        }
      }
      .swiper {
        &-slide-thumb-active {
          .article-title {
            color: var(--neutral-5);
          }
        }
      }
    }
  }
}
@include mq(col-desktop) {
  .waf-showcase {
    .preview-swiper {
      .article {
        &-description {
          width: 73%;
        }
        &-title {
          font-size: 4.5rem;
        }
      }
    }
  }
}